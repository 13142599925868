import React, {useState} from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import {getClassName, getLocalImagePath,backToPrev} from '@/common_js/common';
const VmsModalPopup = dynamic(() => import('../../VmsComponent/ModalPopup').then((module) => module.VmsModalPopup));
const VmsButton = dynamic(() => import('../../VmsComponent/Button').then((module) => module.VmsButton));
export default function SomethingWentWrong(props) {

  const [reload,setReload]=useState(false);

  const handleReload=()=>{
    document.querySelector("body").style.overflow = "auto";
    backToPrev()
  }

  const handleOnClose=()=>{
    document.querySelector("body").style.overflow = "auto";
    backToPrev()
  }

  return (
    <div>
      <div className={getClassName('something-went-wrong-main-container')}>
      {
        <VmsModalPopup
        isMobileView={props.isMobile}
        isOpen={true}
        closeButton={true}
        closeButtonAlignment="right"
        onAfterClose={handleOnClose}
        >
        <div className={getClassName('something-went-erong-main')}>
          <div className={getClassName('something-went-erong-main-img')}>
          <Image src={getLocalImagePath("something-went-wrong.png")} alt="went_wrong"  width={100} height={100} style={{ width: '100%', height: 'auto', maxHeight: '100%'}} />
          </div>
        <p>Error!</p>
        <h4>Something went wrong</h4>
        <VmsButton className={getClassName('something-went-erong-button')} onClick={handleReload}>Go Back</VmsButton>
        </div>
        </VmsModalPopup>
      }
      </div>
    </div>
  )
}
