import axios from "axios";
import { getHeaders } from "../common_js/common.js";

export default async function homepagecmscontent(req) {
  try {
    let resp = await axios.post("/hotels/api/getCmsContent", req, {
      headers: getHeaders(),
    });
    resp.data.statusCode=resp.status
    resp.data.statusText=resp.statusText
    resp.data.api_url="/hotels/api/getCmsContent"
    return resp.data;
  } catch (error) {
    return false;
  }
};
