import Adobe from './adobe';
import deepMergeObject from './deepMergeObject';
let userData = {
    "customerID": '',
    "type": 'Anonymous'
};
const pageInfo = {
    pageName: "Hotels Homepage",
    siteSection: "Hotels Homepage",
    language: "en",
    journeyFlow: "Booking",
};
module.exports = {
    setUserDetails: function (userInfo) {
        try {
            if (userInfo) {
                userData = {
                    "type": userInfo?.role_code,
                    "customerID": Adobe.generateHash(userInfo.customer_id),
                    "tier": userInfo?.tier,
                    "FFNumber": userInfo?.FFNumber || ""
                }
            }
        } catch (error) {
            console.log("adobe err:", error)
        }
    },
    pageLoad: function (eventData, errorDetails) {
        try {
            const adobeData = {
                event: "pageload_hotel",
                interactionType: "Pageload Hotel",
                page: {
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        lob: "Hotel"
                        // journeyFlow: pageInfo.journeyFlow,
                    },
                    eventInfo: {
                        outboundLinkName: "",
                        outboundLinkURL: "",
                    },
                    error: {
                        ...errorDetails
                    }
                },
                loyalty: {
                    pointsEarn : "0",
                    pointsBurn : "0",
                },
                user: userData,
            }

            const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, adobeData.user);
            if (commonData) {
                const combined = deepMergeObject(commonData, adobeData);
                window.adobeDataLayer = window.adobeDataLayer || []
                window.adobeDataLayer.push(combined)
            }
        } catch (error) {
            console.log("adobe err:", error)
        }
    },

    onPopupClick: function (eventData) {
        try {
            const adobeData = {
                event: "click",
                page: {
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        journeyFlow: pageInfo.journeyFlow,
                        lob: "Hotel"
                    },
                    eventInfo: {
                        outboundLinkName: "",
                        outboundLinkURL: "",
                        name: eventData.name,
                        position: eventData.position,
                        component: eventData.component
                    },
                    error: {},
                },
                interactionType: "Pop Up shown",
                user: userData,
            }
            const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, adobeData.user);
            if (commonData) {
                const combined = deepMergeObject(commonData, adobeData);
                window.adobeDataLayer = window.adobeDataLayer || []
                window.adobeDataLayer.push(combined)
            }
        }
        catch (error) {
            console.log("adobe err:", error)
        }
    },

    onPopupButtonClick: function (eventData) {
        try {
            const adobeData = {
                event: "click",
                page: {
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        journeyFlow: pageInfo.journeyFlow,
                        lob: "Hotel"
                    },
                    eventInfo: {
                        name: eventData.name,
                        position: eventData.position,
                        component: eventData.component,
                        outboundLinkName: "",
                        outboundLinkURL: "",
                    },
                    error: {},
                },
                interactionType: "Link/ButtonClick",
                // user: userData,
            }
            const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, adobeData.user);
            if (commonData) {
                const combined = deepMergeObject(commonData, adobeData);
                window.adobeDataLayer = window.adobeDataLayer || []
                window.adobeDataLayer.push(combined)
            }
        } catch (error) {
            console.log("adobe err:", error)
        }
    },

    onBannerClick: function (bannerName) {
        const pageInfo = {
            pageName: "Hotels Homepage",
            siteSection: "Hotel Homepage",
            language: "en",
            journeyFlow: "Booking",
        }
        try {
            const adobeData = {
                event: "click",
                interactionType: "Link/ButtonClick",
                page: {
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        journeyFlow: pageInfo.journeyFlow,
                        lob: "Hotel",
                        banner: {
                            name: bannerName,
                            url: ''
                        }
                    },
                    eventInfo: {
                        outboundLinkName: "",
                        outboundLinkURL: "",
                        name: bannerName,
                        position: "",
                        component: 'Banner',
                    },
                    error: {},
                },
                // user: userData
            }
            const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, adobeData.user)
            if (commonData) {
                const combined = deepMergeObject(commonData, adobeData);
                window.dataLayer = window.dataLayer || []
                window.adobeDataLayer.push(combined)
            }
        } catch (error) {
            console.log("adobe err:", error)
        }
    },

    error: async function (errorDetails) {
        const adobeData = {
            page: {
                pageInfo: {
                    pageName: pageInfo.pageName,
                    siteSection: pageInfo.siteSection,
                    language: pageInfo.language,
                    journeyFlow: pageInfo.journeyFlow,
                    lob: "Hotel"
                },
                eventInfo: {
                    outboundLinkName: "",
                    outboundLinkURL: "",
                    name: "Error",
                    component: 'Pop Up'
                },
                error: {
                    message: errorDetails.message,
                    status: errorDetails.status,
                    url: errorDetails.url,
                    statusCode: errorDetails.statusCode,
                    statusText: errorDetails.statusText
                },
            },
            interactionType: "Error",
            event: "error",
            // user: userData
        }
        const errorAdobeData = {
            page: {
                eventInfo: {
                    interactionType: "Error",
                    name: "Error",
                    component: 'Pop Up'
                }
            }
        }
        const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, userInfo)
        // const errorData = Adobe.errorPopUp(errorAdobeData.page.eventInfo)

        if (commonData) {
            const combined = deepMergeObject(commonData, adobeData);
            window.dataLayer = window.dataLayer || []
            window?.adobeDataLayer?.push(combined)
        }
    }
}