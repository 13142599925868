// import apiRequest from '../common_js/request';
import CryptoJS from "crypto-js"
var key = CryptoJS.enc.Hex.parse('ig_htl_adobe_2024');
var iv = CryptoJS.enc.Hex.parse('ig_htl');
module.exports = {
    /**
     * This function will accept the two objects as arguments and return the object of deeply 
     * merged with nested properties.
     * @param {object} targetObject objects containing the properties to be merged with source.
     * @param {object} sourceObject objects containing the properties you want to apply.
     * @return {object} return the deeply merged objects
     */
    deepMergeObject: function (targetObject = {}, sourceObject = {}) {
        // clone the source and target objects to avoid the mutation
        const copyTargetObject = JSON.parse(JSON.stringify(targetObject));
        const copySourceObject = JSON.parse(JSON.stringify(sourceObject));
        // Iterating through all the keys of source object
        Object.keys(copySourceObject).forEach((key) => {
            if (typeof copySourceObject[key] === "object" && !Array.isArray(copySourceObject[key])) {
                // If property has nested object, call the function recursively
                copyTargetObject[key] = deepMergeObject(
                    copyTargetObject[key],
                    copySourceObject[key]
                );
            } else {
                // else merge the object source to target
                copyTargetObject[key] = copySourceObject[key];
            }
        });

        return copyTargetObject;
    },

    pageInteractions: function (pageInfo, eventInfo, userInfo, errorInfo) {
        try {
            // let hotelToken = parse('hotel_token')
            // if (hotelToken && hotelToken != 'undefined') {
            //     userdetails = encDec.decrypt(hotelToken)
            // }
            let data = {
                page: {
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        projectName: "Hotel",
                        journeyFlow: pageInfo.journeyFlow
                    },
                    eventInfo: {
                        outboundLinkName: eventInfo.outboundLinkName,
                        outboundLinkURL: eventInfo.outboundLinkURL
                    }
                },
            }
            if (errorInfo) {
                data.page.error = {
                    id: errorInfo?.id,
                    type: errorInfo?.type,
                    url: errorInfo?.url,
                    soruce: errorInfo?.soruce,
                    message: errorInfo?.message,
                    statusMessage: errorInfo?.statusMessage,
                    stausCode: errorInfo?.stausCode
                }
            }
            return data;
        } catch (error) {
            console.log("adobe err:", error)
        }
    },

    errorPopUp: function (errorInfo) {
        try {
            let data = {
                interactionType: "Pop Up shown",
                page: {
                    eventInfo: {
                        // interactionType:errorInfo.interactionType,
                        name: errorInfo.name,
                        position: errorInfo.position,
                        component: errorInfo?.component
                    }
                }

            }
            return data;
        } catch (error) {
            console.log("adobe err:", error)
        }
    },
    generateHash: (data) => {
        let encrypted = CryptoJS.AES.encrypt(JSON.stringify({ data }), key, {
            mode: CryptoJS.mode.CTR,
            iv: iv,
            padding: CryptoJS.pad.NoPadding
        });
        return encrypted.toString()
    },
    decrypt: (data) => {
        let decrypted = CryptoJS.AES.decrypt(data, key, {
            mode: CryptoJS.mode.CTR,
            iv: iv,
            padding: CryptoJS.pad.NoPadding
        });

        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)).data
    }
}


