import { format } from "date-fns"
import common from "./common";
import getCmsContent from "../actions/getCmsContent";
import { pageLoad, error } from '../adobe/homePage.adobe'
const pageInfo = {
    pageName: "Hotels Homepage",
    siteSection: "Hotels Homepage",
    language: "en",
    journeyFlow: "Booking",
};
const userInfo = {
    customerID: "",
    type: "Anonymous"
};
let homePageFunction = {
    getInitialValues: async () => {
        let request = {
            "page_name": "homepage"
        }
        let seo_req = {
            "page_name": "SEO"
        }
        let cmsData = await getCmsContent(request);
        let errorDetails = {
            id: "",
            type: "",
            url: !cmsData?.status ? cmsData?.api_url : "",
            source: "",
            status: "",
            displayMessage: cmsData?.message || "",
            statusCode: cmsData?.statusCode || "",
            statusMessage: ""
        }
        let seo_data = await getCmsContent(seo_req);
        let seoData = []
        if (seo_data?.data && Object.keys(seo_data?.data)?.length) {
            Object.keys(seo_data.data).forEach((ele) => {
                seoData.push(seo_data.data[ele])
            })
        }
        {/* Adobe implementation for PageLoad in Homapage*/ }
        if (!cmsData?.status) {
            errorDetails = {
                id: "E001",
                type: "api",
                source: "api",
                status: cmsData?.status,
                message: cmsData?.message,
                url: cmsData?.api_url,
                statusCode: cmsData?.statusCode,
                statusText: cmsData?.statusText
            }
            error(errorDetails)
        }
        pageLoad(errorDetails);
        {/* Adobe implementation for PageLoad in Homapage*/ }
        return { cmsData, seoData }
    },
    recentSearchClick: (data) => {
        data.roomString = common.getroomString(data.guest_details)

        if (data.search_type == 21) {
            localStorage.setItem("adobe_dest_type", "Property")
            homePageFunction.redirectToHdp(data)
        }
        else {
            localStorage.setItem("adobe_dest_type", "Destination")
            homePageFunction.redirectToSrp(data);
        }
    },
    redirectToHdp: async (data) => {
        const query = {
            "searchtext": data.dest_name,
            "checkin": data.checkin,
            "checkout": data.checkout,
            "country": data.country,
            "mop": "accrual",
            "rooms": data.roomString,
            "search_type": data.search_type,
            "vid": data.destination_id,
            "supplier_id": ""
        }
        common.redirect("/hoteldetails", query)
    },
    redirectToSrp: async (data) => {
        let urlData = {
            "region": data.dest_name,
            "mop": "accrual",
            "view": "tiles",
            "country": data.country,
            "destination_id": data.destination_id,
            "search_type": data.search_type,
            "checkin": format(new Date(data.checkin), 'yyyy-MM-dd'),
            "checkout": format(new Date(data.checkout), 'yyyy-MM-dd'),
            "rooms": `${data.roomString}`,
        }
        common.redirect("/searchdetail", urlData)
    },
    getCmsDataBySection: async (...params) => {
        let request = {
            "section_names": [...params]
        }
        let cmsData = await getCmsContent(request);
        return cmsData.data
    }
}

module.exports = homePageFunction